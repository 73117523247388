import "core-js/modules/es.array.push.js";
import { getDateStr } from '@/utils/dateUtils';
import Eldialog from "@/components/Eldialog/index.vue";
export default {
  components: {
    Eldialog,
    ChartBmsMore: defineAsyncComponent(() => import("@/views/attendance/components/ChartBmsMore.vue"))
  },
  data() {
    return {
      initHis: false,
      valueDate: [],
      dataListBMS: [],
      mDischargeSurplusTime: '',
      mChargeSurplusTime: '',
      mMaxChargeInterval: '',
      mCurChargeInterval: '',
      mMaxVoltage: '',
      mMinVoltage: '',
      mRatedCapacity: '',
      mActualCapacity: '',
      mHardwareVer: '',
      hasMosRec: true,
      hasMosDis: true,
      mAlias: '未上线',
      mModelName: '-',
      mBatteryName: '-',
      mLocationTime: '-',
      mReceiveTime: '-',
      mLocationType: '-',
      mSignal: '-',
      mSatellites: '-',
      mBatteryVoltage: '-',
      mACCON: false,
      mLat: 0,
      mLng: 0,
      //
      mRefreshDate: '-',
      mCurSn: '',
      isMosRec: false,
      isMosDis: false,
      isEleLink: false,
      isRec: false,
      mSocImg: 1,
      mCurA: '-',
      mCurV: '-',
      mCurSoc: '-',
      mSurplusCapacity: '-',
      mRecIndex: '-',
      marginV: '-',
      maxVol: '-',
      mixVol: '-',
      batterySpeSwitch: false,
      batterySystime: '-',
      batteryVer: '-',
      batteryBt: '-',
      mAlarm1: '-',
      mAlarm2: '-',
      mAlarm3: '-',
      mAlarm4: '-',
      mAlarm5: '-',
      mAlarm6: '-',
      mAlarm7: '-',
      mAlarm8: '-',
      mAlarm9: '-',
      mAlarm10: '-',
      mAlarm11: '-',
      mAlarm12: '-',
      mAlarm13: '-',
      mAlarm14: '-',
      mAlarm15: '-',
      mAlarm16: '-',
      mAlarm17: '-',
      mAlarm18: '-',
      mAlarm19: '-',
      tempList: [],
      batteryList: [[{
        pos: '',
        value: '-'
      }, {
        pos: '',
        value: '-'
      }, {
        pos: '',
        value: '-'
      }, {
        pos: '',
        value: '-'
      }, {
        pos: '',
        value: '-'
      }]]
    };
  },
  mounted() {},
  methods: {
    initGPS(bean) {},
    //初始化
    init(item, sn, date, pid) {
      this.mCurSn = sn;
      if (date != null && date.length > 18) {
        var curDate = new Date(date);
        var yy = curDate.getFullYear();
        var mm = curDate.getMonth();
        var dd = curDate.getDate();
        var hh = curDate.getHours();
        var ms = curDate.getMinutes();
        this.valueDate = [new Date(yy, mm, dd, hh - 2, ms), new Date(yy, mm, dd, hh, ms)];
        if (this.dataListBMS.length == 0) {
          this.initBMSUI();
          this.getBmsHis(sn, 235, 4);
        }
      }
      //###################################################################################
      if (pid == 2) {
        var maxVol = 0;
        var mixVol = 0;
        var batteryList = item.BatteryVoltages;
        var batteryListTemp = [];
        batteryList.forEach((items, index) => {
          var vol = parseFloat(items.Voltage / 1000).toFixed(3);
          if (index == 0) {
            maxVol = vol;
            mixVol = vol;
          }
          if (vol > maxVol) {
            maxVol = vol;
          } else if (vol < mixVol) {
            mixVol = vol;
          }
          batteryListTemp.push({
            pos: index,
            value: vol
          });
        });
        this.maxVol = maxVol;
        this.mixVol = mixVol;
        this.marginV = (maxVol - mixVol).toFixed(3);
        this.batteryList = this.group(batteryListTemp, 5);
      } else if (pid == 3) {
        this.mRefreshDate = date;
        var tempList = [];
        item.BatteryTemperatures.forEach((item, index) => {
          tempList.push({
            pos: 1 + index,
            name: '温度' + (index + 1),
            value: item.Temperature
          });
        });

        //温度数组
        tempList.push({
          pos: 0,
          name: '环境温度',
          value: item.EnvironmentTemperature
        });
        tempList.push({
          pos: 1,
          name: '功率管温度',
          value: item.MOSTemperature
        });
        this.tempList = this.group(tempList, 3);
        this.mCurA = (item.Current * 0.001).toFixed(2);
        var str = item.MOSStatus.toString(2);
        var len = str.length;
        var bit1 = len > 1 ? str.substr(len - 2, 1) : '0';
        var bit2 = len > 2 ? str.substr(len - 3, 1) : '0';
        //修改状态反了
        this.isMosDis = bit1 == '1';
        this.isMosRec = bit2 == '1';
        this.batteryVer = item.SoftwareVer;
        var overVoltageStatusStr = item.OverVoltageStatus.toString(2);
        var lenA = overVoltageStatusStr.length;
        var bitA0 = lenA > 0 ? str.substr(lenA - 1, 1) : '0';
        var bitA1 = lenA > 1 ? str.substr(lenA - 2, 1) : '0';
        var bitA4 = lenA > 4 ? str.substr(lenA - 5, 1) : '0';
        var underVoltageProtectionStatusStr = item.UnderVoltageProtectionStatus.toString(2);
        var lenB = underVoltageProtectionStatusStr.length;
        var bitB0 = lenB > 0 ? str.substr(lenB - 1, 1) : '0';
        var bitB1 = lenB > 1 ? str.substr(lenB - 2, 1) : '0';
        var temperatureProtectionStatusStr = item.TemperatureProtectionStatus.toString(2);
        var lenC = temperatureProtectionStatusStr.length;
        var bitC0 = lenC > 0 ? str.substr(lenC - 1, 1) : '0';
        var bitC1 = lenC > 1 ? str.substr(lenC - 2, 1) : '0';
        var bitC2 = lenC > 2 ? str.substr(lenC - 3, 1) : '0';
        var bitC4 = lenC > 4 ? str.substr(lenC - 5, 1) : '0';
        var bitC5 = lenC > 5 ? str.substr(lenC - 6, 1) : '0';
        var protectionStatusStr = item.ProtectionStatus.toString(2);
        var lenD = protectionStatusStr.length;
        var bitD0 = lenD > 0 ? str.substr(lenD - 1, 1) : '0';
        var bitD1 = lenD > 1 ? str.substr(lenD - 2, 1) : '0';
        var bitD2 = lenD > 2 ? str.substr(lenD - 3, 1) : '0';
        var bitD4 = lenD > 4 ? str.substr(lenD - 5, 1) : '0';
        var bitD5 = lenD > 5 ? str.substr(lenD - 6, 1) : '0';
        var failureStatusStr = item.FailureStatus.toString(2);
        var lenE = failureStatusStr.length;
        var bitE0 = lenE > 0 ? str.substr(lenE - 1, 1) : '0';
        var bitE1 = lenE > 1 ? str.substr(lenE - 2, 1) : '0';
        var bitE2 = lenE > 2 ? str.substr(lenE - 3, 1) : '0';
        var bitE3 = lenE > 3 ? str.substr(lenE - 4, 1) : '0';
        this.mAlarm1 = bitA0 == '1';
        this.mAlarm2 = bitA1 == '1';
        this.mAlarm3 = bitA4 == '1';
        this.mAlarm4 = bitB0 == '1';
        this.mAlarm5 = bitB1 == '1';
        this.mAlarm6 = bitC0 == '1';
        this.mAlarm7 = bitC1 == '1';
        this.mAlarm8 = bitC2 == '1';
        this.mAlarm9 = bitC4 == '1';
        this.mAlarm10 = bitC5 == '1';
        this.mAlarm11 = bitD0 == '1';
        this.mAlarm12 = bitD1 == '1';
        this.mAlarm13 = bitD2 == '1';
        this.mAlarm14 = bitD4 == '1';
        this.mAlarm15 = bitD5 == '1';
        this.mAlarm16 = bitE0 == '1';
        this.mAlarm17 = bitE1 == '1';
        this.mAlarm18 = bitE2 == '1';
        this.mAlarm19 = bitE3 == '1';
      } else if (pid == 4) {
        this.mCurV = (item.Voltage * 0.001).toFixed(2);
        this.mCurSoc = item.SOC;
        this.mSurplusCapacity = (item.SurplusCapacity / 1000).toFixed(2);
        this.mRecIndex = item.CycleNumber;
        this.mDischargeSurplusTime = item.DischargeSurplusTime == '65535' ? '**' : item.DischargeSurplusTime;
        this.mChargeSurplusTime = item.ChargeSurplusTime == '65535' ? '**' : item.ChargeSurplusTime;
        this.mCurChargeInterval = item.CurChargeInterval;
        this.mMaxChargeInterval = item.MaxChargeInterval;
        this.mMaxVoltage = (item.MaxVoltage / 1000).toFixed(2);
        this.mMinVoltage = (item.MinVoltage / 1000).toFixed(2);
        this.mRatedCapacity = (item.RatedCapacity / 1000).toFixed(2);
        this.mActualCapacity = (item.ActualCapacity / 1000).toFixed(2);
        this.mHardwareVer = item.HardwareVer;
        if (this.mCurSoc == 0) {
          this.mSocImg = 0;
        } else if (this.mCurSoc <= 25) {
          this.mSocImg = 1;
        } else if (this.mCurSoc <= 50) {
          this.mSocImg = 2;
        } else if (this.mCurSoc <= 75) {
          this.mSocImg = 3;
        } else {
          this.mSocImg = 4;
        }
      } else if (pid == 17) {
        this.batteryBt = item.TermID == null ? '无数据' : item.TermID == '' ? '空白' : item.TermID;
      }
    },
    onSendOrderTag(tag) {
      // var param = ''
      // switch (tag) {
      //   case '充电MOS打开': {
      //     param = getOrderParam(this.mCurSn, '8900_CLY', '27')
      //     break
      //   }
      //   case '充电MOS关闭': {
      //     param = getOrderParam(this.mCurSn, '8900_CLY', '28')
      //     break
      //   }
      //   case '放电MOS打开': {
      //     param = getOrderParam(this.mCurSn, '8900_CLY', '25')
      //     break
      //   }
      //   case '放电MOS关闭': {
      //     param = getOrderParam(this.mCurSn, '8900_CLY', '26')
      //     break
      //   }
      // }
      // this.$confirm('是否发送[' + tag + ']指令', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // })
      //   .then(() => {
      //     this.sendOrderApi(param)
      //   })
      //   .catch(() => {})
    },
    //发送指令
    onSendOrder() {
      this.$emit('onSendOrder', 'GPS,CLY');
    },
    //处理数据
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    async sendOrderApi(param) {
      // var { success, msg } = await sendOrder(param)
      // if (success) {
      //   this.$baseMessage('发送成功', 'success')
      //   // this.dialogFormVisible = false
      //   // setTimeout(() => {
      //   //   this.sendRefresh()
      //   // }, 500)
      // } else {
      //   if (msg == '') {
      //     this.$baseMessage('发送失败', 'error')
      //   } else {
      //     this.$baseMessage('失败:' + msg, 'error')
      //   }
      // }
    },
    // async sendRefresh() {
    //   var param = getOrderParam(
    //     this.mCurSn,
    //     '8900',
    //     '4E5700130000000006030000000000006800000129'
    //   )
    //   var { success, msg } = await sendOrder(param)
    // },
    initOrder(tempList) {},
    async getBmsHis(sn, type, ptid) {
      //遍历循环请求
      await this.getBmsHisRequest(sn, 1, 300, type, ptid);
      if (this.dataListBMS.length > 0) {
        this.setBMSUI();
      }
      this.initHis = true;
    },
    async getBmsHisRequest(sn, page, pageSize, type, ptid) {
      // const { response } = await getBmsHistory({
      //   Key: sn,
      //   type: type,
      //   ptid: ptid,
      //   StartTime: getDateStr(this.valueDate[0]),
      //   EndTime: getDateStr(this.valueDate[1]),
      //   page: page,
      //   pageSize: pageSize,
      // })
      // this.dataListBMS = this.dataListBMS.concat(response.data)
      // if (response.page < response.pageCount) {
      //   await this.getBmsHisRequest(
      //     sn,
      //     response.page + 1,
      //     pageSize,
      //     type,
      //     ptid
      //   )
      // }
    },
    initBMSUI() {
      var dateStr = getDateStr(new Date());
      setTimeout(() => {
        this.$refs['bmschart'].init([[{
          value: 0,
          date: dateStr
        }, {
          value: 0,
          date: dateStr
        }, {
          value: 0,
          date: dateStr
        }]], ['电压',, 'SOC', '剩余容量'], ['V', '%', 'ah'], ['#6AD6E6', '#6F95DA', '#47ba80'], 3);
      }, 300);
    },
    setBMSUI() {
      this.list = this.dataListBMS;
      //单体电池
      var bmsList = [];
      //遍历当前数据列表-全部
      var time = '';
      for (let index = 0; index < this.list.length; index++) {
        var item = this.list[index];
        if (item.ReceiveTime == time) {
          break;
        }
        time = item.ReceiveTime;
        bmsList.push([{
          value: item.Voltage * 0.001,
          date: item.ReceiveTime
        }, {
          value: item.SOC,
          date: item.ReceiveTime
        }, {
          value: item.SurplusCapacity * 0.001,
          date: item.ReceiveTime
        }]);
        //以下是判断如果两点间隔大于 15分钟，就补充两个空白时间点，折线图才不会很突兀。
        if (index + 1 < this.list.length) {
          var time1 = Date.parse(new Date(item.ReceiveTime));
          var item2 = this.list[index + 1];
          var time2 = Date.parse(new Date(item2.ReceiveTime));
          //补充空白数据 间隔 15分钟
          if (time2 - time1 >= 15 * 60 * 1000) {
            //补充温度空白数据
            bmsList.push([{
              value: 0,
              date: getDateStr(new Date(time1 + 60 * 1000))
            }, {
              value: 0,
              date: getDateStr(new Date(time1 + 60 * 1000))
            }, {
              value: 0,
              date: getDateStr(new Date(time1 + 60 * 1000))
            }]);
            bmsList.push([{
              value: 0,
              date: getDateStr(new Date(time2 - 60 * 1000))
            }, {
              value: 0,
              date: getDateStr(new Date(time2 - 60 * 1000))
            }, {
              value: 0,
              date: getDateStr(new Date(time2 - 60 * 1000))
            }]);
          }
        }
      }
      setTimeout(() => {
        this.$refs['bmschart'].init(bmsList, ['电压', 'SOC', '剩余容量'], ['V', '%', 'ah'], ['#6AD6E6', '#6F95DA', '#47ba80'], 3);
      }, 300);
    }
  }
};